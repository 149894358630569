import { Instruction } from './active';
import { bindForm } from './bind';
import './binding-handlers/ajaxRequestVerification';
import './binding-handlers/codeEditor';
import './binding-handlers/command';
import './binding-handlers/datetimepicker';
import './binding-handlers/enterKey';
import './binding-handlers/escKey';
import './binding-handlers/expand';
import './binding-handlers/flags';
import './binding-handlers/fieldOptions';
import './binding-handlers/attachment';
import './binding-handlers/fileset';
import './binding-handlers/file-browse';
import './binding-handlers/flashMessage';
import './binding-handlers/focus';
import './binding-handlers/formTabFocus';
import './binding-handlers/gantt';
import './binding-handlers/gantt-report';
import './binding-handlers/grid-scroll-container';
import './binding-handlers/groupedOptions';
import './binding-handlers/icon-select';
import './binding-handlers/invisible';
import './binding-handlers/linkify';
import './binding-handlers/listFilterMapEditor';
import './binding-handlers/optionEditor';
import './binding-handlers/optionProperty';
import './binding-handlers/paste-receiver';
import './binding-handlers/popover';
import './binding-handlers/readFileValue';
import './binding-handlers/select2';
import './binding-handlers/selectListItem';
import './binding-handlers/subTabFocus';
import './binding-handlers/toggleClass';
import './binding-handlers/updateMask';
import initializeGrid from './grid/initializeGrid';
import './grid/style/index.less';
import { onLoad } from './load';
import { Form, ObjectForm } from './model';
import './style/index.less';
import { settings } from '../../areas/main/config';
import { parseJson } from '../../util/parse';
import $ from 'jquery';

export default function initialize() {
	var objectForm = bindForm();

	var e = $("#active-ops");

	if (e.length) {
		var sequence = parseJson<Instruction[]>(e.html());
		if (sequence.length) {
			objectForm = objectForm || new ObjectForm({});
			objectForm.runOperations(sequence);
		}
	}

	$(window).on('beforeunload', function () {
		if (Form.unloadWarning) {
			if (Form.root && Form.root.hasChanges()) {
				return settings.strings.unsavedChangesWarning;
			}
		}
	});

	initializeGrid($('.grid'));

	onLoad((container) => {
		initializeGrid($('.grid', container));
	})
}
