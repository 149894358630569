import $ from 'jquery';
import ko from 'knockout';
import { Field } from '../model';

export function ensureActiveTab($element) {
	$element.parents("div.tab-pane:not(.active)").each(function () {
		$(`a[href='#${this.id}']`).trigger('click');
	});
}

export function focusFirstField(container: JQuery<HTMLElement>, allowMultipleTimes: boolean = true) {
	if (container) {
		let e = container.find(`
			.select2-focusser:visible:enabled,
			.select2-container-multi .select2-input:visible:enabled,
			.form-control:text:visible:enabled,
			.form-control-checkbox:visible:enabled,
			textarea.form-control:visible:enabled
		`);

		if (e.length == 0) {
			e = container.find('a:visible').not('.btn');
		}

		if (e.length == 0) {
			e = container.find('.btn:visible');
		}

		if (allowMultipleTimes || !e.attr('data-was-focussed')) {
			e.attr('data-was-focussed', 'true');
			e.first().trigger('focus');
		}
	}
}

ko.bindingHandlers['focus'] = {
	init: function (element: HTMLElement, valueAccessor, allBindingsAccessor, viewModel: Field) {
		var event = valueAccessor();
		viewModel[event] = () => {
			ensureActiveTab($(element));
			element.focus();
		};
	}
}
