import $ from 'jquery';
import ko, { BindingContext } from 'knockout';
import { parseClientModel } from '../../../util/parse';
import { ScalarField } from '../../form/model';
import OptionEditor from '../../form/option-editor/OptionEditor';

export function bindOptionEditor(element, valueAccessor, viewModel: ScalarField, bindingContext: BindingContext): OptionEditor {
	const value = valueAccessor();

	const options = typeof value === 'string'
		? { id: value, initialValue: undefined }
		: value

	var data: any = parseClientModel($(`#${options.id}`, element));
	data.options = viewModel.value();
	data.initialValue = options.initialValue;
	data.isDisabled = viewModel.editMode ? viewModel.isDisabled() : true;

	var editor = new OptionEditor(data);

	var innerBindingContext = bindingContext.extend({ optionEditor: editor });
	ko.applyBindingsToDescendants(innerBindingContext, element);

	viewModel.value.subscribe((newValue) => {
		editor.parseOptions(newValue);
	});

	if (viewModel.editMode) {
		editor.options.subscribe(() => {
			viewModel.value(editor.serialize());
		});

		viewModel.isDisabled.subscribe(newValue => editor.isDisabled(newValue));
	}

	return editor;
}

ko.bindingHandlers['optionEditor'] = {
	init: function (element, valueAccessor, _, viewModel: ScalarField, bindingContext: BindingContext) {
		bindOptionEditor(element, valueAccessor, viewModel, bindingContext);

		return {
			controlsDescendantBindings: true
		};
	}
}
