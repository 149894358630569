import $ from 'jquery';
import ko from 'knockout';
import { parseClientModel } from "../../../util/parse";
import { Gantt } from "../grid/gantt";
import { ReportForm } from "../../report/ReportForm";
import _ from 'lodash';
import { compare, GanttModel } from './gantt';

ko.bindingHandlers["gantt-report"] = {
	init(element: HTMLElement, _valueAccessor, _allBindingsAccessor, viewModel: ReportForm, bindingContext) {
		const $element = $(element);
		const $container = $element.parent();
		const model = <GanttModel>parseClientModel($('.gantt-model', $container));
		const template = kendo.template($('#cell-template', $container).html(), { useWithBlock: false });
		let expandable = true;

		const columns = [
			...model.columns.map(c => {
				const field = c.field;
				c.template = (data) => template(data.values[field]);

				if (c.sortable) {
					c.sortable = {
						compare: (x: kendo.data.GanttTask, y: kendo.data.GanttTask) => compare(x.values[field], y.values[field])
					}
				}
				
				if (!c.hidden && c.expandable) {
					//only the first visible expandable column should be expandable
					c.expandable = expandable;
					expandable = false;
				}

				if (c.alignmentClass) {
					c.attributes = {
						"class": c.alignmentClass
					};
				}

				return c;
			})
		];

		async function init() {
			const gantt = new Gantt($element, model.hoursPattern);

			const control = await gantt.initAsync({
				dataSource: {
					schema: {
						model: {
							fields: {
								id: { type: "string" },
								parentId: { type: "string", nullable: true },
								start: { type: "date" },
								end: { type: "date" },
								title: { type: "string" },
								summary: { type: "boolean" },
								expanded: { type: "boolean" },
								percentComplete: { type: "number" }
							}
						}
					},
					transport: {
						read: (operation) => {
							const items = viewModel.items().map((x: any) => {
								const result = new kendo.data.GanttTask({
									id: x[model.idColumn].value,
									parentId: x[model.parentIdColumn]?.value ?? null,
									title: x[gantt.gantt.options.columns.find(x => !x.hidden && x.expandable).field]?.value,
									start: x[model.startColumn].rawValue,
									end: x[model.endColumn].rawValue,
									summary: !x[model.idColumn].value.startsWith('R'),
									expanded: true
								});

								if (model.percentCompleteColumn) {
									const percentComplete = x[model.percentCompleteColumn]?.rawValue;
									if (model.percentCompleteIsInt && percentComplete) {
										result.percentComplete = percentComplete / 100;
									}
									else {
										result.percentComplete = percentComplete;
									}
								}

								result.values = {};

								model.columns.forEach(c => {
									result.values[c.field] = x[c.field] ?? '';
								});

								result.context = x;

								return result;
							});

							gantt.ensureDates(items);
							operation.success(items);
						}
					}
				},
				columns: columns,
				dataBound: _ => gantt.resize()
			});

			viewModel.items.subscribe(_ => {
				control.dataSource.read().then(_ => {
					gantt.resize();
					control.refresh();
				});
			});

			ko.applyBindingsToDescendants(bindingContext, element);
		}

		init();

		return { controlsDescendantBindings: true };
	}
}
