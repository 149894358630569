import $ from 'jquery';
import _ from 'lodash';
import { settings } from '../../../areas/main/config';
import { offsetFrom } from "../grid/initializeGrid";

export class Gantt {
	readonly rowHeight = 30;

	gantt: kendo.ui.Gantt;
	toolbar: JQuery;
	header: JQuery;
	hoursPattern: string;

	constructor(public element: JQuery, hoursPattern: string, public minHeight?: number) {
		this.hoursPattern = hoursPattern;
	}

	async initAsync(options: kendo.ui.GanttOptions): Promise<kendo.ui.Gantt> {
		await import(/* webpackChunkName: "kendo-scheduler" */ '../../../plugins/kendo/scheduler');

		const autoBind = options.autoBind || true;
		options.autoBind = false;

		if (!options.tooltip) {
			options.tooltip = {
				visible: true,
				template: kendo.template(
					`<div class="k-task-details">
						<strong>#: task.title #</strong>
						#if (task.percentComplete !== null && task.percentComplete !== undefined) {#
							<div class="k-task-pct">#: kendo.toString(task.percentComplete, 'P') #</div>
						#}#
						<ul class="k-reset">
							<li>${settings.strings.start}: #: kendo.toString(task.start, 'G') #</li>
							<li>${settings.strings.end}: #: kendo.toString(task.end, 'G') #</li>
						</ul>
					</div>`
				)
			}
		}

		this.gantt = this.element.kendoGantt({
			...{
				views: [
					{
						type: 'day',
						dayHeaderTemplate: kendo.template('#=kendo.toString(start, "D")#'),
						timeHeaderTemplate: kendo.template(`#=kendo.toString(start, "${this.hoursPattern}")#`),
						slotSize: 40
					},
					{
						type: 'week',
						dayHeaderTemplate: kendo.template('#=kendo.toString(start, "ddd").charAt(0)#'),
						weekHeaderTemplate: kendo.template('#=kendo.toString(start, "d")#'),
						slotSize: 30,
						selected: true
					},
					{
						type: 'month',
						monthHeaderTemplate: kendo.template('#=kendo.toString(start, "Y")#'),
						weekHeaderTemplate: kendo.template('#=kendo.toString(start, "d")#'),
						slotSize: 120
					}
				],
				height: this.rowHeight * 4,
				rowHeight: this.rowHeight,
				resizable: true,
				selectable: false,
				showWorkHours: false,
				showWorkDays: false,
				snap: false,
				editable: false
			},
			...options
		}).data('kendoGantt');

		this.toolbar = this.element.find('.k-gantt-toolbar');
		const treelist = this.element.find('.k-gantt-treelist');
		this.header = treelist.find('.k-grid-header');

		this.element.find('.k-grid-content')
			.on('scroll', _ => {
				treelist
					.find('[data-toggle="dropdown"][aria-expanded="true"]')
					.dropdown('toggle');
			});

		this.element
			.on('show.bs.dropdown', e => {
				const toggle = $(e.target);
				const dropdown = toggle.find('.dropdown-menu');
				const content = dropdown.closest('.k-grid-content');

				let { absolute, relative } = offsetFrom(toggle, content);

				relative.top += content.scrollTop();

				const clipTolerance = 30;
				const dropdownHeight = dropdown.outerHeight();

				if (absolute.top + dropdownHeight + clipTolerance > $(window).innerHeight()) {
					//the the window will clip the menu, show it above instead
					relative.top -= dropdownHeight
					dropdown.addClass('above');
				}
				else {
					relative.top += toggle.outerHeight();
					dropdown.removeClass('above');
				}

				dropdown.css(relative);
			});

		if (autoBind) {
			this.gantt.dataSource.read();
		}

		return this.gantt;
	}

	resize(): void {
		const headerHeight = this.toolbar.outerHeight() + this.header.outerHeight();
		const minHeight = this.minHeight ?? (headerHeight + this.rowHeight * 2);

		var max = $(window).height() - this.gantt.element.offset().top - 10;
		if (max < minHeight) {
			max = minHeight;
		}

		//we add 21 to prevent vertical scroll from always shown
		var height = headerHeight + (this.rowHeight * this.gantt.dataSource.total()) + 21;
		if (height > max) {
			height = max;
		}

		this.gantt.wrapper.height(height);
		this.gantt.resize(true);
	}

	//This is a workaround since kendo currently doesn't support null start/end dates. If one or
	//more tasks are missing a start date then even valid tasks are not displayed on the timeline.
	//Valid tasks will display as long as all tasks have at least a start date, and tasks with only a
	//start date are not displayed on the timeline.
	//Feature Request: https://feedback.telerik.com/kendo-jquery-ui/1359454-add-support-for-tasks-without-dates-in-gantt-chart
	ensureDates(tasks: kendo.data.GanttTask[]): void {
		let minStart: Date;
		const invalidTasks = [];
		
		for (let task of tasks) {
			//fall back to the end date if start date is missing
			task.start = task.start || task.end;
		
			//fall back to the start date if end date is missing
			task.end = task.end || task.start;
		
			if (!task.start) {
				invalidTasks.push(task);
			}
			else if (!minStart || minStart > task.start) {
				minStart = task.start;
			}
		}
		
		//set anything task without a start date to use the min start date
		//to ensure the timeline is displayed for all other valid tasks
		invalidTasks.forEach(x=> x.start = minStart);
	}
}
