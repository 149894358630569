import jQuery from 'jquery';

jQuery($ => {
    $(document).on('click', '.k-panel > header aside.fixed-height-toggle', e => {
        const toggle = $(e.currentTarget);
        const panel = toggle.closest('.k-panel');
        const height = parseInt(toggle.data('height'));

        const setFixed = () => panel.css('height', height);
        const setAuto = () => panel.css('height', 'auto');

        if (toggle.is('.active')) {
            toggle.removeClass('active');
            setAuto();
        }
        else {
            toggle.addClass('active');
            setFixed();
        }
    });
})