import 'bootstrap/js/tooltip';
import jQuery from 'jquery';
import { settings } from '../../areas/main/config';
import '../../plugins/jquery.loadmask';
import { error as showError } from '../notify';
import { bindForm, findForm, unbindForm } from './bind';
import { Form } from './model';

const eventId = "konfigure:load";

export function loadForm(container: JQuery, url: string) {
	let form = tryFindForm(container);
	if (!form) {
		return;
	}

	container.mask(settings.strings.loadingMessage);

	unbindForm(container);

	let hash = url && url.split('#')[1];
	window.location.hash = hash || '';

	try {
		container.load(url, () => bindAndRaise(container));
		return;
	}
	catch (e) {
		var error = `Unable to load {url}`;
		console.log(error, e);
		showError((<Error>e).message, error);

		bindAndRaise(container);
		container.unmask();
	}
}

export function loadFormContent(container: JQuery, content: string) {
	let form = tryFindForm(container);
	if (!form) {
		return;
	}

	unbindForm(container);
	container.html(content);
	bindAndRaise(container);
}

export function onLoad(handler: (container: JQuery) => void) {
	jQuery(document).on(eventId, (e: JQuery.TriggeredEvent) => {
		handler(jQuery(e.target));
	});
}

function bindAndRaise(container: JQuery) {
	bindForm(container);
	jQuery(container).trigger(eventId);
}

function tryFindForm(container: JQuery): Form {
	var form = findForm(container);
	if (form && !form.tryConfirmHasChanges()) {
		return null;
	}

	return form;
}
