import $ from 'jquery';
import ko, { Observable } from 'knockout';
import _ from 'lodash';
import { settings } from '../../../areas/main/config';
import { makeAbstractOption, Option, SelectField } from '../model';
import { ensureActiveTab } from './focus';
import { focusTab } from './formTabFocus';

let cache = {};

ko.bindingHandlers['select2'] = {
	init(element, valueAccessor, allBindingsAccessor, viewModel: SelectField) {
		var e = $(element);
		var value: Observable<any> = valueAccessor();

		import (/* webpackChunkName: "select2" */ '../../../plugins/select2').then(m => {
			var initSelect2 = (e: JQuery, data: any) => {
				var allowAbstract = e.attr('data-allow-abstract').toLowerCase() === 'true';

				var options: Select2Options = {
					width: 'off',
					placeholder: settings.strings.select,
					allowClear: true,
					openOnEnter: false,
					multiple: (e.attr('data-multiple').toLowerCase() === 'true'),
					selectOnBlur: (e.attr('data-select-on-blur').toLowerCase() === 'true'),
					closeOnSelect: (e.attr('data-close-on-select').toLowerCase() !== 'false'),
					data: data,
					dropdownCssClass: () => viewModel.isValid() ? null : 'error',
					initSelection: (element, callback) => {
						var options = viewModel.getOptions(element.val());
						if (viewModel.multiple) {
							callback(options);
						}
						else {
							callback(options[0]);
						}
					},
					createSearchChoice: (term, data) => {
						if (allowAbstract) {
							var text = term.toLowerCase();
							var found = _.some(data, (x: Option) => x.text.toLowerCase().localeCompare(text) === 0);

							if (found) {
								return null;
							} else {
								return makeAbstractOption(term);
							}
						}
						else {
							return null;
						}
					}
				};

				if (viewModel.selectFormatter) {
					if (viewModel.selectFormatter.formatSelection) {
						options.formatSelection = viewModel.selectFormatter.formatSelection;
					}

					if (viewModel.selectFormatter.formatResult) {
						options.formatResult = viewModel.selectFormatter.formatResult;
					}
				}

				var url = e.attr('data-url');
				if (url) {
					options.data = null;
					var pageSize = 50;

					//@ts-ignore the type checker can not match on `transport: AjaxFunction`
					//even though the signature of our function exactly matches one of the
					//permitted calls - I suspect this may be a peculiary of some structural
					//mismatch with jQuery types, but I am unable to resolve it.
					options.ajax = {
						url: url,
						//@ts-ignore
						transport: (settings: JQueryAjaxSettings): JQueryXHR => {
							var success = settings.success;
							var cacheKey = settings.url + "/" + JSON.stringify(settings.data);
							var cached = cache[cacheKey];

							function invokeSuccess(data, status: JQuery.Ajax.SuccessTextStatus, xhr: JQueryXHR) {
								if (success instanceof Array) {
									for (let f of success) {
										f(data, status, xhr);
									}
								}
								else {
									success(data, status, xhr);
								}
							}

							if (!cached) {
								settings.type = 'POST';
								settings.success = (data, status: JQuery.Ajax.SuccessTextStatus, xhr: JQueryXHR) => {
									cache[cacheKey] = { data: data, status: status };
									invokeSuccess(data, status, xhr);
								}
								return $.ajax(settings);
							}
							else {
								invokeSuccess(cached.data, cached.status, null);
								return null;
							}
						},
						quietMillis: allowAbstract ? (settings.inputSearchDelay / 2) : settings.inputSearchDelay,
						dataType: 'json',
						data: (term, page: number) => {
							var data = {
								i: viewModel.form.dataId() || 0,
								term: term,
								o: (page - 1) * pageSize,
								n: pageSize
							};

							viewModel.buildFilterSnapshot(data, true);

							return data;
						},
						results: (data, page: number, query) => {
							data = data || [];
							var length = data.length;

							return {
								results: data,
								more: (length >= pageSize)
							};
						}
					};
				}

				$.when(
					e.select2(options)
				).done(() => {
					if (viewModel.isVisible() && !viewModel.uneditable()) {
						focusTab(null, false);
					}
				});

				if (viewModel.multiple) {
					var onSelect = () => {
						var item = $("li.select2-search-choice-focus", e.select2("container"));
						if (!item.length) {
							viewModel.selectedItem(null);
						}
						else {
							viewModel.selectedItem(value()[item.index()]);
						}
					};

					e.on('change', onSelect)
						.on('open', onSelect);

					e.select2('container')
						.find('ul.select2-choices')
						.on('click', onSelect);

					if (e.attr('data-sortable').toLowerCase() === 'true' && !viewModel.uneditable()) {
						import (/* webpackChunkName: "jquery-sortable" */ 'jquery-ui/ui/widgets/sortable').then(_ => {
							e.select2("container")
								.find("ul.select2-choices")
								.sortable({
									containment: 'parent',
									start() {
										e.select2("onSortStart");
									},
									update() {
										e.select2("onSortEnd");
									}
								});
						});
					}
				}
				else if (allowAbstract) {
					e.on('select2-open', () => {
						var val = e.select2('data');
						if (val && val.isAbstract) {
							e.select2('search', val.text);
						}
					});
				}

				e.select2('readonly', viewModel.uneditable());
			}

			initSelect2(e, viewModel.options());

			e.on('change', () => {
				if (viewModel.objectValues) {
					viewModel.setValue(e.select2('data'));
				}
				else {
					viewModel.setValue(e.select2('val'));
				}
			});

			e.on('select2-selecting', (evt) => {
				if (viewModel.objectValues && evt.choice) {
					if ((evt.choice as any).isAbstract) {
						viewModel.setValue(evt.choice);
					}
				}
			});

			if (viewModel.multiple) {
				e.on('select2-close', _ => viewModel.onBlur());
			}
			else {
				e.on('select2-blur', _ => viewModel.onBlur());
			}

			viewModel.onFocus = () => {
				ensureActiveTab(e);
				e.select2('focus');
			}

			var optionsSubscription =
				viewModel.options.subscribe(function (newValue) {
					initSelect2(e, newValue);
				});

			var uneditableSubscription =
				viewModel.uneditable.subscribe(function (newValue) {
					e.select2('readonly', newValue);
				});

			// invalidating the viewmodel without select2 interaction doesn't call syncCssClasses
			let isValidSubscription =
				viewModel.isValid.subscribe(function (newValue) {
					let drop = e.select2('container')
						.find('.select2-drop');

					if (drop) {
						if (newValue) {
							drop.removeClass('error');
						}
						else {
							drop.addClass('error');
						}
					}
				});

			ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
				optionsSubscription.dispose();
				uneditableSubscription.dispose();
				isValidSubscription.dispose();

				window.requestAnimationFrame(() => {
					e.select2('destroy');
				});
			});
		});
	},

	update: function (element, valueAccessor, allBindingsAccessor, viewModel: SelectField) {
		var value = ko.utils.unwrapObservable(valueAccessor());

		import (/* webpackChunkName: "select2" */ '../../../plugins/select2').then(m => {
			if (viewModel.objectValues) {
				$(element).select2('data', value);
			}
			else {
				if (value !== $(element).select2('val')) {
					if (_.isArray(value)) {
						$(element).select2('val', value);
					}
					else {
						$(element).select2('val', _.isNull(value) ? '' : value + '');
					}
				}
			}
		});
	}
};
