import $ from 'jquery';
import ko from 'knockout';
import appUrl from '../../../util/appUrl';
import { getCurrentPopup } from '../../dialog/popup';
import { error as showError } from '../../notify';
import { loadForm, loadFormContent } from '../load';
import { Command, Form, ObjectForm } from '../model';

ko.bindingHandlers['command'] = {
	init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
		const e = $(element);
		const callback: (c: Command) => void = valueAccessor();
		const linkCommand = 'link';

		//This is extremely convoluted. Unfortunately, barring a redesign, the
		//only way to programatically trigger a command is via the html element.
		//Things are further complicated if this command is part of an ajax
		//enabled form. The html is continually regenerated, and this init code
		//runs each time. This causes all sorts of problems with detached
		//elements being captured in closures. This workaround requires the
		//server to tag an element with a stable key which is persistent across
		//ajax updates, and we use this to locate the element.
		const triggerKey = e.attr('data-trigger-key');

		const matchCommand = /command:\s*(\w+)/.exec(e.attr('data-bind'))
		const callbackName = matchCommand ? matchCommand[1] : undefined;

		if (viewModel instanceof Form && triggerKey && callbackName) {
			viewModel.registerCommandTrigger(callbackName, () => {
				$(`[data-trigger-key=${triggerKey}]`).trigger('click');
			});
		}

		e.on('click', function () {
			let command: Command = {
				command: e.attr('data-command'),
				argument: e.attr('data-argument'),
				isAsync: e.attr('data-is-async') == 'true',
				isPartial: e.attr('data-is-partial') == 'true',
				shouldCancel: e.attr('data-should-cancel') == 'true',
				proceed: null,
				openInNewWindow: e.attr('data-new-window') == 'true',
				includeHash: e.attr('data-include-hash') == 'true'
			}

			if (command.command === linkCommand) {
				command.isAsync = true;
			}

			command.proceed = function (form) {
				ko.tasks.runEarly();

				const $form = e.closest('form');
				const $container = $form.parent();

				if (command.command === linkCommand && command.argument) {
					if (form.returnUrl) {
						location.href = form.returnUrl;
					}
					else {
						if (command.includeHash) {
							command.argument = command.argument + window.location.hash;
						}

						const url = appUrl(command.argument);

						if (command.openInNewWindow) {
							window.open(url);
						}
						else if (command.isPartial) {
							loadForm($container, url);
						}
						else {
							window.location.assign(url);
						}
					}
				}
				else if (command.command == 'popupCallback') {
					const p = getCurrentPopup();
					if (p) {
						p.callbackArgument = command.argument;
						p.close();
					}
				}
				else {
					$form.find('.form-command').val(command.command || '');
					$form.find('.form-argument').val(command.argument);
					$form.find('.form-hash').val(window.location.hash);

					if (command.openInNewWindow) {
						$form.attr("target", "_blank");
					}

					if (command.shouldCancel == true) {
						$form.attr('action', $form.attr('data-cancel-action'));
						$form.find('.form-return-url').val(form.returnUrl);
					}

					if ($form.attr('data-use-ajax-submit') == 'true' && !command.isAsync && !command.openInNewWindow) {
						const data = $form.serialize();

						$.ajax({
							url: $form.attr('action'),
							type: "POST",
							data: data,
							success: (result, status: string, xhr: JQueryXHR) => {
								if (!xhr.responseJSON) {
									form.suppressUnloadWarning();
									loadFormContent($container, xhr.responseText);
								}
								else if (result.succeeded) {
									$form.trigger("form:save", [form, result]);
									form.suppressUnloadWarning();

									if (result.redirectUrl) {
										window.location.assign(result.redirectUrl);
									}
									else {
										form.isSaving(false);
										loadForm($container, result.partialReloadUrl);
									}
								}
								else {
									if (form instanceof ObjectForm) {
										form.applyEntityErrors(result.entity);
									}
									else {
										form.applyErrors(result.errors);
									}

									form.isSaving(false);
								}
							},
							error: (jqXHR: JQueryXHR, status: string, error: string) => {
								form.isSaving(false);
								showError(error);
							}
						});
					}
					else {
						form.suppressUnloadWarning();
						$form.trigger('submit');
					}
				}
			}
			const confirmation = e.attr('data-confirmation');
			if (!confirmation || confirm(confirmation)) {
				callback(command);
			}
			return false;
		});
	}
}
