import { FileField, List, ListItemForm, ScalarField, SelectField } from "../model";
import $ from 'jquery';
import ko from 'knockout';
import { parseClientModel } from "../../../util/parse";
import { expand } from "./expand";
import { Gantt } from "../grid/gantt";

export interface GanttModel {
	idColumn: string;
	parentIdColumn: string;
	startColumn: string;
	endColumn: string;
	percentCompleteColumn: string;
	percentCompleteIsInt?: boolean;
	hoursPattern: string;
	columns: any[];
}

declare global {
	module kendo {
		module data {
			interface GanttTask {
				values?: {[index: string]: any;};

				viewUrl?: string;
				editUrl?: string;
				cloneUrl?: string;
				canRemove?: boolean;
				isRemoved?: boolean;

				context?: any;
			}
		}
	}
}

class GanttValue {
	value: string;
	rawValue: any;
	linkUrl?: string;
	linkTarget?: string;
	infoUrl?: string;
	imageUrl?: string;

	row: kendo.data.GanttTask;
}

export function compare(x: GanttValue, y: GanttValue) {
	function resolveValue(v: GanttValue) {
		let value = v ? v.rawValue : v;

		if (value instanceof Object) {
			if (value.hasOwnProperty('text')) {
				value = value.text;
			}
		}

		return value;
	}

	const a = resolveValue(x);
	const b = resolveValue(y);

	if (a === null && b === null) {
		return 0;
	}
	else if (a === null || a < b) {
		return -1
	}
	else if (b === null || a > b) {
		return 1;
	}
	else {
		return 0;
	}
}

ko.bindingHandlers["gantt"] = {
	init(element: HTMLElement, _valueAccessor, _allBindingsAccessor, viewModel: List, bindingContext) {
		const $element = $(element);
		const $container = $element.parent();
		const model = <GanttModel>parseClientModel($('.gantt-model', $container));
		const template = kendo.template($('#cell-template', $container).html(), { useWithBlock: false });

		let expandable = true;

		const columns = [
			{
				width: "86px",
				expandable: false,
				template: kendo.template($('#actions-template', $container).html(), { useWithBlock: false }),
				attributes: {
					"class": "actions"
				}
			},
			...model.columns.map(c => {
				const field = c.field;
				c.template = (data) => template(data.values[field]);

				if (c.sortable) {
					c.sortable = {
						compare: (x: kendo.data.GanttTask, y: kendo.data.GanttTask) => compare(x.values[field], y.values[field])
					}
				}

				if (!c.hidden) {
					//the first visible column should be expandable
					c.expandable = expandable;
					expandable = false;
				}

				if (c.alignmentClass) {
					c.attributes = {
						"class": c.alignmentClass
					};
				}

				return c;
			})
		];

		async function init() {
			const gantt = new Gantt($element, model.hoursPattern);

			const control = await gantt.initAsync({
				dataSource: {
					schema: {
						model: {
							fields: {
								id: { type: "string" },
								parentId: { type: "string", nullable: true }
							}
						}
					},
					transport: {
						read: (operation) => {
							const parents = new Set();

							const items = viewModel.items().map((x: ListItemForm) => {
								if (x.parentKey) {
									parents.add(x.parentKey);
								}

								const result = new kendo.data.GanttTask({
									id: x.key,
									parentId: x.parentKey || null,
									title: x.displayName,
									start: x.fields.map[model.startColumn].value(),
									end: x.fields.map[model.endColumn].value(),
									cloneUrl: x.cloneUrl,
									//only allow root elements to be removed
									canRemove: x.canRemove && !x.parentKey,
									isRemoved: x.isRemoved(),
								});

								if (model.percentCompleteColumn) {
									result.percentComplete = x.fields.map[model.percentCompleteColumn].value()
								}

								result.values = {};

								model.columns.forEach(c => {
									const field = x.fields.map[c.field];
									if (field instanceof ScalarField) {
										if (field.isVisible()) {
											const value = new GanttValue();

											value.value = field.displayValue();
											value.rawValue = field.value();

											if (!field.isNull()) {
												value.linkUrl = expand(field.linkUrl, field);
												value.linkTarget = field.linkTarget;

												if (field instanceof SelectField) {
													value.infoUrl = field.buildInfoUrl();
												}
												else if (!x.parentKey) {
													//info urls are only shown for the display name of root elements as child elements
													//could have a different type
													value.infoUrl = expand(field.infoUrl, field);
												}

												if (field instanceof FileField) {
													value.imageUrl = expand(field.imageUrl, field);
												}
											}

											value.row = result;

											result.values[field.id] = value;
										}
										else {
											result.values[field.id] = "";
										}
									}
								});

								if (x.showViewButton) {
									result.viewUrl = x.url;
								}

								if (x.showEditButton) {
									result.editUrl = x.editUrl;
								}

								result.context = x;

								return result;
							});

							for (let n = 0; n < items.length; n++) {
								const task = items[n];

								if (parents.has(task.id)) {
									task.summary = true;
								}
							}

							gantt.ensureDates(items);
							operation.success(items);
						}
					}
				},
				columns: columns,
				dataBound: _ => gantt.resize()
			});

			viewModel.items.subscribe(_ => {
				control.dataSource.read().then(_ => {
					gantt.resize();
					control.refresh();
				});
			});

			$element
				.on('click', 'button.remove', e => {
					const row = $(e.currentTarget).closest('tr');
					const rowModel = control.dataItem(row);
					const item: ListItemForm = rowModel.context;

					item.remove();
					rowModel.isRemoved = item.isRemoved();

					if (rowModel.isRemoved) {
						row.addClass("removed");
					}

					control.refresh();
					e.preventDefault();
				})
				.on('click', 'button.restore', e => {
					const row = $(e.currentTarget).closest('tr');
					const rowModel = control.dataItem(row);
					const item: ListItemForm = rowModel.context;

					item.restore();
					rowModel.isRemoved = false;
					row.removeClass("removed");

					control.refresh();
					e.preventDefault();
				});

			ko.applyBindingsToDescendants(bindingContext, element);
		}

		init();

		return { controlsDescendantBindings: true };
	}
}
