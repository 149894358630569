import _ from 'lodash';
import ko, { Observable, ObservableArray } from 'knockout';
import { clear as clearUserFilter, save as saveUserFilter, UserFilterType } from '../../components/form/filter/UserFilter';
import { Command, IFilterable, ObjectForm, ScalarField } from '../form/model';
import { setQueryParameters } from '../../util/queryString';

const filterPrefix = 'rq';

export class ReportForm extends ObjectForm implements IFilterable {
	hasUserFilter: Observable<boolean>;
	items: ObservableArray<any>;

	constructor(parameters: any, items: any) {
		super(parameters);

		this.hasChanges = ko.pureComputed(() => false);
		this.hasUserFilter = ko.observable(parameters.hasUserFilter ?? false);
		this.items = ko.observableArray(items);
	}

	run(command: Command) {
		this.updateQueryParameters();
		this.save(command);
	}

	clear(command: Command) {
		for (let field of this.fields.user) {
			if (field.isVisible()) {
				field.value(null);
			}
		}

		if (this.validate()) {
			this.run(command);
		}
	}

	sort(command: Command) {
		var sortField = this.findField("$Sort");
		if (!sortField) {
			return;
		}

		if (sortField.value() == command.argument) {
			sortField.value("-" + command.argument);
		}
		else {
			sortField.value(command.argument);
		}

		this.run(command);
	}

	updateQueryParameters() {
		let data = {};
		this.snapshotFilter(data, true, filterPrefix);

		const qs = this.mergeQueryParams(data, this.dataId(), filterPrefix);

		setQueryParameters(qs);
	}

	setFilterValue(name: string, value: any) {
		let field = this.fields.map[name];

		if (field instanceof ScalarField) {
			field.parse(value);
		}
	}

	applyFilter(): void {
		this.triggerCommand('run');
	}

	saveUserFilter() {
		let filter = {};
		this.snapshotFilter(filter, true, '', false);

		saveUserFilter(UserFilterType.Report, this.dataId(), filter, () => this.hasUserFilter(true));
	}

	clearUserFilter() {
		clearUserFilter(UserFilterType.Report, this.dataId(), () => this.hasUserFilter(false));
	}
}
