import $ from 'jquery';
import ko from 'knockout';
import { DateField } from '../../model';

ko.bindingHandlers['datetimepicker'] = {
	init: function (element, valueAccessor, allBindingsAccessor, viewModel: DateField) {
		var $element = $(element);
		var dateFormat = $element.attr('data-date-format');
		var timeFormat = $element.attr('data-time-format');

		import(/* webpackChunkName: "kendo-datetime" */ '../../../../plugins/kendo/datetime').then(_ => {
			if (dateFormat) {
				var options: kendo.ui.DatePickerOptions | kendo.ui.DateTimePickerOptions = {
					depth: 'year',
					format: dateFormat
				}

				if (timeFormat) {
					var timeOptions = <kendo.ui.DateTimePickerOptions>options;
					timeOptions.timeFormat = timeFormat;
					$element.kendoDateTimePicker(timeOptions);

					registerForDisposal($element.data('kendoDateTimePicker'));
				}
				else {
					$element.kendoDatePicker(<kendo.ui.DatePickerOptions>options);

					registerForDisposal($element.data('kendoDatePicker'));
				}
			}
			else if (timeFormat) {
				$element.kendoTimePicker({
					format: timeFormat
				});

				registerForDisposal($element.data('kendoTimePicker'));
			}
		});

		function registerForDisposal(widget: kendo.ui.DatePicker | kendo.ui.DateTimePicker | kendo.ui.TimePicker) {
			if (widget) {
				var subscription = viewModel.isDisabled.subscribe((isDisabled) => {
					widget.enable(!isDisabled);
				});

				ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
					subscription && subscription.dispose();

					window.requestAnimationFrame(() => {
						widget.destroy();
					});
				});
			}
		}
	},
}
